import React from "react";
import { Typography, Button } from "@material-tailwind/react";
import { HiCheck } from "react-icons/hi";
import { Link } from "react-router-dom";

function CTASection() {
    return (
        <section className="w-full h-full bg-white">
            <div className="w-full h-full">
                <div className="w-full h-full grid-cols-1 grid md:grid-cols-2 gap-6">
                    <div className="w-full h-full">
                        <div className="w-full h-full py-6">
                            <Typography
                                variant="h3"
                                color="main"
                                className="mb-5"
                            >
                                Flowbite in iOS: Take control of your finances
                                with us
                            </Typography>
                            <Typography
                                variant="h6"
                                color="mainLight"
                                className="mb-5 font-medium"
                            >
                                Our app helps users easily track their expenses
                                and create a budget. With a user-friendly
                                interface, the app allows users to quickly input
                                their income and expenses, and then
                                automatically categorizes them for easy
                                tracking.
                            </Typography>
                            <div className="w-full h-[1px] bg-main-100 my-6"></div>
                            <ul className="w-full h-auto">
                                <li className="w-full flex items-center gap-2 mb-4">
                                    <div className="w-auto h-auto">
                                        <div className="w-5 h-5 rounded-full bg-brand-100 flex items-center justify-center">
                                            <HiCheck className="text-sm text-brand-500" />
                                        </div>
                                    </div>
                                    <div className="w-auto h-auto">
                                        <Typography color="main">
                                            Continuous integration and
                                            deployment
                                        </Typography>
                                    </div>
                                </li>
                                <li className="w-full flex items-center gap-2 mb-4">
                                    <div className="w-auto h-auto">
                                        <div className="w-5 h-5 rounded-full bg-brand-100 flex items-center justify-center">
                                            <HiCheck className="text-sm text-brand-500" />
                                        </div>
                                    </div>
                                    <div className="w-auto h-auto">
                                        <Typography color="main">
                                            Development workflow
                                        </Typography>
                                    </div>
                                </li>
                                <li className="w-full flex items-center gap-2 mb-4">
                                    <div className="w-auto h-auto">
                                        <div className="w-5 h-5 rounded-full bg-brand-100 flex items-center justify-center">
                                            <HiCheck className="text-sm text-brand-500" />
                                        </div>
                                    </div>
                                    <div className="w-auto h-auto">
                                        <Typography color="main">
                                            Knowledge management
                                        </Typography>
                                    </div>
                                </li>
                                <li className="w-full flex items-center gap-2 mb-4">
                                    <div className="w-auto h-auto">
                                        <div className="w-5 h-5 rounded-full bg-brand-100 flex items-center justify-center">
                                            <HiCheck className="text-sm text-brand-500" />
                                        </div>
                                    </div>
                                    <div className="w-auto h-auto">
                                        <Typography color="main">
                                            Dynamic reports and dashboards
                                        </Typography>
                                    </div>
                                </li>
                                <li className="w-full flex items-center gap-2 mb-4">
                                    <div className="w-auto h-auto">
                                        <div className="w-5 h-5 rounded-full bg-brand-100 flex items-center justify-center">
                                            <HiCheck className="text-sm text-brand-500" />
                                        </div>
                                    </div>
                                    <div className="w-auto h-auto">
                                        <Typography color="main">
                                            Limitless business automation
                                        </Typography>
                                    </div>
                                </li>
                            </ul>
                            <Typography
                                variant="h6"
                                color="mainLight"
                                className="mt-6 font-medium"
                            >
                                Flow Budget takes the hassle out of budgeting
                                and empowers users to take control of their
                                finances
                            </Typography>
                            <Link to="/contact-us">
                                <Button
                                    variant="text"
                                    className="flex items-center gap-2 mt-6 ml-[-1.4rem]"
                                >
                                    Contact us{" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        className="h-5 w-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                        />
                                    </svg>
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className="w-full h-full">
                        <div className="w-full h-full flex items-center justify-center">
                            <img
                                className="h-auto w-full rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50"
                                src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                                alt="nature"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CTASection;
