import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const formSchema = Yup.object({
    name: Yup.string().min(2).max(25).required("Please enter your name."),
    email: Yup.string().email().required("Please enter your email."),
    number: Yup.string().min(10).max(10).matches(phoneRegExp, 'Phone number is not valid').required("Please enter your mobile number."),
    message: Yup.string().min(2).required("Please enter your message."),
});
