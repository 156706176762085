import React from "react";
import {
    Carousel,
    Typography,
    Button,
    IconButton,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from "@material-tailwind/react";
import { NavbarMain } from "../components/Navbar/Navbar";
import BackHome from "../assets/images/slide2.webp";
import Experts from "../components/Experts";
// import { UserIcon } from "@heroicons/react/24/solid";
import Testimonials from "../components/Testimonials";
import ProductSlider from "../components/ProductSlider";
import Footer from "../components/Footer";
import Video from "../assets/images/offset.mp4";
import Logo from "../components/Logo";

// function StarIcon() {
//     return (
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 24 24"
//             fill="currentColor"
//             className="h-5 w-5 text-main-500"
//         >
//             <path
//                 fillRule="evenodd"
//                 d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
//                 clipRule="evenodd"
//             />
//         </svg>
//     );
// }

// function StarIconWhite() {
//     return (
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 24 24"
//             fill="currentColor"
//             className="h-5 w-5 text-white"
//         >
//             <path
//                 fillRule="evenodd"
//                 d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
//                 clipRule="evenodd"
//             />
//         </svg>
//     );
// }

function Home() {
    return (
        <div className="w-full h-full">
            <NavbarMain />
            <div className="w-full h-full">
                <Carousel
                    autoplay={true}
                    autoplayDelay={5000}
                    loop={true}
                    transition={{ duration: 2 }}
                    prevArrow={({ handlePrev }) => (
                        <IconButton
                            variant="text"
                            color="white"
                            size="lg"
                            onClick={handlePrev}
                            className="!absolute top-2/4 left-4 -translate-y-2/4"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                />
                            </svg>
                        </IconButton>
                    )}
                    nextArrow={({ handleNext }) => (
                        <IconButton
                            variant="text"
                            color="white"
                            size="lg"
                            onClick={handleNext}
                            className="!absolute top-2/4 !right-4 -translate-y-2/4"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                                />
                            </svg>
                        </IconButton>
                    )}
                    navigation={({ setActiveIndex, activeIndex, length }) => (
                        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                                <span
                                    key={i}
                                    className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                        activeIndex === i
                                            ? "w-8 bg-white"
                                            : "w-4 bg-white/50"
                                    }`}
                                    onClick={() => setActiveIndex(i)}
                                />
                            ))}
                        </div>
                    )}
                >
                    <div className="relative h-screen w-full">
                        <img
                            src={BackHome}
                            alt="1"
                            className="h-full w-full object-cover"
                        />
                        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/50">
                            <div className="w-3/4 text-center md:w-2/4">
                                <Typography
                                    variant="h1"
                                    color="white"
                                    className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                                >
                                    The Beauty of Nature
                                </Typography>
                                <Typography
                                    variant="lead"
                                    color="white"
                                    className="mb-12 opacity-80"
                                >
                                    It is not so much for its beauty that the
                                    forest makes a claim upon men&apos;s hearts,
                                    as for that subtle something, that quality
                                    of air that emanation from old trees, that
                                    so wonderfully changes and renews a weary
                                    spirit.
                                </Typography>
                                <div className="flex justify-center gap-2">
                                    <Button size="lg" color="white">
                                        Explore
                                    </Button>
                                    <Button
                                        size="lg"
                                        color="white"
                                        variant="text"
                                    >
                                        Gallery
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative h-screen w-full">
                        <img
                            src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
                            alt="2"
                            className="h-full w-full object-cover"
                        />
                        <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
                            <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
                                <Typography
                                    variant="h1"
                                    color="white"
                                    className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                                >
                                    The Beauty of Nature
                                </Typography>
                                <Typography
                                    variant="lead"
                                    color="white"
                                    className="mb-12 opacity-80"
                                >
                                    It is not so much for its beauty that the
                                    forest makes a claim upon men&apos;s hearts,
                                    as for that subtle something, that quality
                                    of air that emanation from old trees, that
                                    so wonderfully changes and renews a weary
                                    spirit.
                                </Typography>
                                <div className="flex gap-2">
                                    <Button size="lg" color="white">
                                        Explore
                                    </Button>
                                    <Button
                                        size="lg"
                                        color="white"
                                        variant="text"
                                    >
                                        Gallery
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative h-screen w-full">
                        <img
                            src="https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80"
                            alt="3"
                            className="h-full w-full object-cover"
                        />
                        <div className="absolute inset-0 grid h-full w-full items-end bg-black/75">
                            <div className="w-3/4 pl-12 pb-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
                                <Typography
                                    variant="h1"
                                    color="white"
                                    className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                                >
                                    The Beauty of Nature
                                </Typography>
                                <Typography
                                    variant="lead"
                                    color="white"
                                    className="mb-12 opacity-80"
                                >
                                    It is not so much for its beauty that the
                                    forest makes a claim upon men&apos;s hearts,
                                    as for that subtle something, that quality
                                    of air that emanation from old trees, that
                                    so wonderfully changes and renews a weary
                                    spirit.
                                </Typography>
                                <div className="flex gap-2">
                                    <Button size="lg" color="white">
                                        Explore
                                    </Button>
                                    <Button
                                        size="lg"
                                        color="white"
                                        variant="text"
                                    >
                                        Gallery
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>

                <div className="w-full h-full">
                    <div className="w-full h-full py-20">
                        <div className="max-w-[1235px] h-full mx-auto px-6">
                            <div className="w-full h-full grid-cols-1 grid md:grid-cols-2 gap-6">
                                <div className="w-full h-full">
                                    <div className="w-full h-full py-6">
                                        <Typography
                                            variant="h6"
                                            color="brand"
                                            className="mb-2"
                                        >
                                            Montech Offset
                                        </Typography>
                                        <Typography
                                            variant="h2"
                                            color="main"
                                            className="mb-5"
                                        >
                                            Technological Innovation for
                                            Business Success
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            color="mainLight"
                                            className="mb-5 font-medium"
                                        >
                                            Innovative web development solutions
                                            that propel business growth and
                                            elevate user experiences
                                        </Typography>
                                        <Typography
                                            variant="paragraph"
                                            color="mainLight"
                                            className="mb-6"
                                        >
                                            Since 2000, Montech Design has been
                                            at the forefront of technological
                                            innovation, providing cutting-edge
                                            solutions to businesses worldwide.
                                            Our passionate team of experts
                                            specializes in web development,
                                            software engineering, app
                                            development, Shopify, and Magento
                                            solutions. We pride ourselves on our
                                            ability to transform ideas into
                                            reality, enhancing user experiences
                                            and driving business growth.
                                        </Typography>
                                        <Button
                                            color="brand"
                                            size="lg"
                                            variant="gradient"
                                        >
                                            About us
                                        </Button>
                                    </div>
                                </div>
                                <div className="w-full h-full">
                                    <div className="w-full h-full flex items-center justify-center">
                                        <img
                                            className="h-auto w-full rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50"
                                            src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                                            alt="nature"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-full bg-gray-100 py-20">
                        <div className="max-w-[1235px] h-full mx-auto px-6">
                            <div className="w-full h-full mb-16">
                                <div className="w-full h-full">
                                    <div className="w-full h-full mb-4">
                                        <Typography
                                            variant="h2"
                                            color="main"
                                            className="text-center"
                                        >
                                            Our Products
                                        </Typography>
                                    </div>
                                    <div className="max-w-4xl mx-auto">
                                        <Typography
                                            variant="lead"
                                            color="main"
                                            className="text-center"
                                        >
                                            We're constantly trying to express
                                            ourselves and actualize our dreams.
                                            If you have the opportunity to play.
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                <Card className="mt-6 w-full">
                                    <CardHeader
                                        color="blue-gray"
                                        className="relative h-auto"
                                    >
                                        <img
                                            src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                                            alt="card"
                                            className="w-full h-56 object-cover"
                                        />
                                    </CardHeader>
                                    <CardBody className="h-1/2">
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Sticker / Labels
                                        </Typography>
                                        <Typography>
                                            The place is close to Barceloneta
                                            Beach and bus stop just 2 min by
                                            walk and near to
                                            &quot;Naviglio&quot; where you can
                                            enjoy the main night life in
                                            Barcelona.
                                        </Typography>
                                    </CardBody>
                                    <CardFooter className="pt-0">
                                        <Button>Read More</Button>
                                    </CardFooter>
                                </Card>

                                <Card className="mt-6 w-full">
                                    <CardHeader
                                        color="blue-gray"
                                        className="relative h-auto"
                                    >
                                        <img
                                            src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                                            alt="card"
                                            className="w-full h-56 object-cover"
                                        />
                                    </CardHeader>
                                    <CardBody className="h-1/2">
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Box / Mono Carton
                                        </Typography>
                                        <Typography>
                                            The place is close to Barceloneta
                                            Beach and bus stop just 2 min by
                                            walk and near to
                                            &quot;Naviglio&quot; where you can
                                            enjoy the main night life in
                                            Barcelona.
                                        </Typography>
                                    </CardBody>
                                    <CardFooter className="pt-0">
                                        <Button>Read More</Button>
                                    </CardFooter>
                                </Card>

                                <Card className="mt-6 w-full">
                                    <CardHeader
                                        color="blue-gray"
                                        className="relative h-auto"
                                    >
                                        <img
                                            src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                                            alt="card"
                                            className="w-full h-56 object-cover"
                                        />
                                    </CardHeader>
                                    <CardBody className="h-1/2">
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Catalogue
                                        </Typography>
                                        <Typography>
                                            The place is close to Barceloneta
                                            Beach and bus stop just 2 min by
                                            walk and near to
                                            &quot;Naviglio&quot; where you can
                                            enjoy the main night life in
                                            Barcelona.
                                        </Typography>
                                    </CardBody>
                                    <CardFooter className="pt-0">
                                        <Button>Read More</Button>
                                    </CardFooter>
                                </Card>

                                <Card className="mt-6 w-full">
                                    <CardHeader
                                        color="blue-gray"
                                        className="relative h-auto"
                                    >
                                        <img
                                            src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                                            alt="card"
                                            className="w-full h-56 object-cover"
                                        />
                                    </CardHeader>
                                    <CardBody className="h-1/2">
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Hospital Stationary
                                        </Typography>
                                        <Typography>
                                            The place is close to Barceloneta
                                            Beach and bus stop just 2 min by
                                            walk and near to
                                            &quot;Naviglio&quot; where you can
                                            enjoy the main night life in
                                            Barcelona.
                                        </Typography>
                                    </CardBody>
                                    <CardFooter className="pt-0">
                                        <Button>Read More</Button>
                                    </CardFooter>
                                </Card>

                                <Card className="mt-6 w-full">
                                    <CardHeader
                                        color="blue-gray"
                                        className="relative h-auto"
                                    >
                                        <img
                                            src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                                            alt="card"
                                            className="w-full h-56 object-cover"
                                        />
                                    </CardHeader>
                                    <CardBody className="h-1/2">
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Books
                                        </Typography>
                                        <Typography>
                                            The place is close to Barceloneta
                                            Beach and bus stop just 2 min by wal
                                            e place is close to Barceloneta
                                            Beach and bus stop just 2 min by wal
                                            bus stop just 2 min ce is close to
                                            Barceloneta
                                        </Typography>
                                    </CardBody>
                                    <CardFooter className="pt-0">
                                        <Button>Read More</Button>
                                    </CardFooter>
                                </Card>

                                <Card className="mt-6 w-full">
                                    <CardHeader
                                        color="blue-gray"
                                        className="relative h-56"
                                    >
                                        <img
                                            src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                                            alt="card"
                                            className="w-full h-56 object-cover"
                                        />
                                    </CardHeader>
                                    <CardBody className="h-1/2">
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Custome Printing
                                        </Typography>
                                        <Typography>
                                            The place is close to Barceloneta
                                            Beach and bus stop just 2 min by wal
                                        </Typography>
                                    </CardBody>
                                    <CardFooter className="pt-0">
                                        <Button>Read More</Button>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-full pt-20 pb-10">
                        <div className="max-w-[1235px] h-full mx-auto px-6">
                            <Experts />
                        </div>
                    </div>

                    {/* <div className="w-full h-full py-20 bg-main-500">
                        <div className="max-w-[1235px] h-full mx-auto px-6">
                            <div className="w-full h-full mb-24">
                                <div className="w-full flex justify-center">
                                    <IconButton color="white">
                                        <UserIcon className="size-6 text-main-500" />
                                    </IconButton>
                                </div>
                                <div className="w-full h-full">
                                    <div className="w-full h-full mt-10 mb-4">
                                        <Typography
                                            variant="h1"
                                            color="white"
                                            className="text-center"
                                        >
                                            What Clients Say
                                        </Typography>
                                    </div>
                                    <div className="max-w-4xl mx-auto">
                                        <Typography
                                            variant="lead"
                                            color="white"
                                            className="text-center"
                                        >
                                            We're constantly trying to express
                                            ourselves and actualize our dreams.
                                            If you have the opportunity to play.
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full h-full">
                                <div className="w-full grid grid-cols-3 gap-6">
                                    <Card
                                        color="transparent"
                                        shadow={false}
                                        className="w-full max-w-[26rem] p-6"
                                    >
                                        <CardHeader
                                            color="transparent"
                                            floated={false}
                                            shadow={false}
                                            className="mx-0 flex flex-col justify-start gap-6 pt-0 pb-5"
                                        >
                                            <Avatar
                                                size="lg"
                                                variant="rounded"
                                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                                                alt="tania andrew"
                                            />
                                            <div className="flex w-full flex-col gap-0.5">
                                                <div className="flex items-center justify-between">
                                                    <Typography
                                                        variant="h5"
                                                        color="white"
                                                    >
                                                        Tania Andrew
                                                    </Typography>
                                                </div>
                                                <Typography color="white">
                                                    Frontend Lead @ Google
                                                </Typography>
                                            </div>
                                        </CardHeader>
                                        <CardBody className="mb-6 p-0">
                                            <Typography color="white">
                                                &quot;I found solution to all my
                                                design needs from Creative Tim.
                                                I use them as a freelancer in my
                                                hobby projects for fun! And its
                                                really affordable, very humble
                                                guys !!!&quot;
                                            </Typography>
                                        </CardBody>
                                        <CardFooter className="p-0 mb-2">
                                            <div className="5 flex items-center gap-0">
                                                <StarIconWhite />
                                                <StarIconWhite />
                                                <StarIconWhite />
                                                <StarIconWhite />
                                                <StarIconWhite />
                                            </div>
                                        </CardFooter>
                                    </Card>
                                    <Card
                                        color="white"
                                        shadow={false}
                                        className="w-full max-w-[26rem] p-6"
                                    >
                                        <CardHeader
                                            color="transparent"
                                            floated={false}
                                            shadow={false}
                                            className="mx-0 flex flex-col justify-start gap-6 pt-0 pb-5"
                                        >
                                            <Avatar
                                                size="lg"
                                                variant="rounded"
                                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                                                alt="tania andrew"
                                            />
                                            <div className="flex w-full flex-col gap-0.5">
                                                <div className="flex items-center justify-between">
                                                    <Typography
                                                        variant="h5"
                                                        color="mian"
                                                    >
                                                        Tania Andrew
                                                    </Typography>
                                                </div>
                                                <Typography color="main">
                                                    Frontend Lead @ Google
                                                </Typography>
                                            </div>
                                        </CardHeader>
                                        <CardBody className="mb-6 p-0">
                                            <Typography color="main">
                                                &quot;I found solution to all my
                                                design needs from Creative Tim.
                                                I use them as a freelancer in my
                                                hobby projects for fun! And its
                                                really affordable, very humble
                                                guys !!!&quot;
                                            </Typography>
                                        </CardBody>
                                        <CardFooter className="p-0 mb-2">
                                            <div className="5 flex items-center gap-0">
                                                <StarIcon />
                                                <StarIcon />
                                                <StarIcon />
                                                <StarIcon />
                                                <StarIcon />
                                            </div>
                                        </CardFooter>
                                    </Card>
                                    <Card
                                        color="transparent"
                                        shadow={false}
                                        className="w-full max-w-[26rem] p-6"
                                    >
                                        <CardHeader
                                            color="transparent"
                                            floated={false}
                                            shadow={false}
                                            className="mx-0 flex flex-col justify-start gap-6 pt-0 pb-5"
                                        >
                                            <Avatar
                                                size="lg"
                                                variant="rounded"
                                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                                                alt="tania andrew"
                                            />
                                            <div className="flex w-full flex-col gap-0.5">
                                                <div className="flex items-center justify-between">
                                                    <Typography
                                                        variant="h5"
                                                        color="white"
                                                    >
                                                        Tania Andrew
                                                    </Typography>
                                                </div>
                                                <Typography color="white">
                                                    Frontend Lead @ Google
                                                </Typography>
                                            </div>
                                        </CardHeader>
                                        <CardBody className="mb-6 p-0">
                                            <Typography color="white">
                                                &quot;I found solution to all my
                                                design needs from Creative Tim.
                                                I use them as a freelancer in my
                                                hobby projects for fun! And its
                                                really affordable, very humble
                                                guys !!!&quot;
                                            </Typography>
                                        </CardBody>
                                        <CardFooter className="p-0 mb-2">
                                            <div className="5 flex items-center gap-0">
                                                <StarIconWhite />
                                                <StarIconWhite />
                                                <StarIconWhite />
                                                <StarIconWhite />
                                                <StarIconWhite />
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="w-full h-full py-20 bg-gray-100">
                        <div className="max-w-full mx-auto">
                            <ProductSlider />
                        </div>
                    </div>

                    <div className="w-full h-full">
                        <div className="relative max-w-full mx-auto">
                            <video
                                className="max-h-[550px] h-full w-full object-cover"
                                autoPlay
                                muted
                                loop
                            >
                                <source src={Video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 flex flex-col justify-center items-center text-white">
                                <Typography variant="h1" color="white">
                                    We Will Gald To Assist You
                                </Typography>
                                <Typography variant="h1" color="white">
                                    We Will Gald To Assist You
                                </Typography>
                                <Typography variant="h1" color="white">
                                    We Will Gald To Assist You
                                </Typography>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-full py-10 bg-white">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <Logo />
                        </div>
                    </div>

                    <div className="w-full h-full py-20 bg-gray-100">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <Testimonials />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
