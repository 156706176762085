import React from "react";
import {
    Navbar,
    Collapse,
    Typography,
    Button,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import {
    ChevronDownIcon,
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function NavListMenu() {
    const [openMenu, setOpenMenu] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

    return (
        <React.Fragment>
            <Menu
                open={openMenu}
                handler={setOpenMenu}
                placement="bottom"
                offset={{ mainAxis: 20 }}
                allowHover={true}
                animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                }}
            >
                <MenuHandler>
                    <Typography
                        as="div"
                        variant="small"
                        className="font-medium"
                    >
                        <ListItem
                            className="flex items-center justify-between gap-2 py-2 pr-4 font-medium text-gray-900"
                            selected={openMenu || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            Products
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    openMenu ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <Link to="/sticker">
                        <MenuItem>Sticker / Labels</MenuItem>
                    </Link>
                    <MenuItem>Box / Mono Carton</MenuItem>
                    <MenuItem>Catalogue</MenuItem>
                    <MenuItem>Hospital Stationary</MenuItem>
                    <MenuItem>Books</MenuItem>
                    <MenuItem>Custome Printing</MenuItem>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>
                    <Link to="/sticker">
                        <MenuItem>Sticker / Labels</MenuItem>
                    </Link>
                    <a href="/">
                        <MenuItem>Box / Mono Carton</MenuItem>
                    </a>
                    <a href="/">
                        <MenuItem>Catalogue</MenuItem>
                    </a>
                    <a href="/">
                        <MenuItem>Hospital Stationary</MenuItem>
                    </a>
                    <a href="/">
                        <MenuItem>Books</MenuItem>
                    </a>
                    <a href="/">
                        <MenuItem>Custome Printing</MenuItem>
                    </a>
                </Collapse>
            </div>
        </React.Fragment>
    );
}

function NavList() {
    return (
        <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
            <Typography
                as={Link}
                to="/"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    Home
                </ListItem>
            </Typography>
            <NavListMenu />
            <Typography
                as={Link}
                to="/about-us"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    About Us
                </ListItem>
            </Typography>
            <Typography
                as={Link}
                to="/contact-us"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    Contact Us
                </ListItem>
            </Typography>
        </List>
    );
}

export function NavbarMain() {
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false)
        );
    }, []);

    return (
        <div className="w-full p-6 z-50 fixed">
            <Navbar className="max-w-full px-4 py-3">
                <div className="max-w-[1235px] mx-auto px-6">
                    <div className="flex items-center justify-between text-blue-gray-900">
                        <Typography
                            as={Link}
                            to="/"
                            variant="h6"
                            className="mr-4 cursor-pointer py-1.5"
                        >
                            Montech Offset
                        </Typography>
                        <div className="hidden lg:block">
                            <NavList />
                        </div>
                        <div className="hidden gap-2 lg:flex">
                            <Button variant="text" size="sm" color="blue-gray">
                                Log In
                            </Button>
                            <Button variant="gradient" size="sm">
                                Sign In
                            </Button>
                        </div>
                        <IconButton
                            variant="text"
                            color="blue-gray"
                            className="lg:hidden"
                            onClick={() => setOpenNav(!openNav)}
                        >
                            {openNav ? (
                                <XMarkIcon
                                    className="h-6 w-6"
                                    strokeWidth={2}
                                />
                            ) : (
                                <Bars3Icon
                                    className="h-6 w-6"
                                    strokeWidth={2}
                                />
                            )}
                        </IconButton>
                    </div>
                </div>
                <Collapse open={openNav}>
                    <NavList />
                    <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
                        <Button
                            variant="outlined"
                            size="sm"
                            color="blue-gray"
                            fullWidth
                        >
                            Log In
                        </Button>
                        <Button variant="gradient" size="sm" fullWidth>
                            Sign In
                        </Button>
                    </div>
                </Collapse>
            </Navbar>
        </div>
    );
}
