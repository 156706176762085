import React from "react";
import { Breadcrumbs, Typography, Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { NavbarMain } from "../components/Navbar/Navbar";
import Footer from "../components/Footer";

function Sticker() {
    return (
        <div className="w-full h-full">
            <NavbarMain />
            <div className="w-full h-full">
                <div className="relative w-full h-full">
                    <img
                        className="h-96 w-full object-cover object-center"
                        src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                        alt="nature"
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
                        <div className="w-full h-full bg-black opacity-50 absolute top-0 left-0"></div>
                        <div className="relative text-white mt-14">
                            <Breadcrumbs className="bg-opacity-70 bg-white opacity-100">
                                <Link
                                    to="/"
                                    className="opacity-70 text-main-500 hover:text-brand-500"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                    </svg>
                                </Link>
                                <Typography
                                    variant="small"
                                    className="opacity-70 text-main-500 hover:text-brand-500"
                                >
                                    Product
                                </Typography>
                                <Link
                                    to="/about-us"
                                    className="font-bold text-main-500 hover:text-brand-500"
                                >
                                    Sticker
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </div>
                </div>

                <div className="w-full h-full">
                    <div className="w-full h-full py-20">
                        <div className="max-w-[1235px] h-full mx-auto px-6">
                            <div className="w-full h-full grid-cols-1 grid md:grid-cols-2 gap-6">
                                <div className="w-full h-full">
                                    <div className="w-full h-full py-6">
                                        <Typography
                                            variant="h6"
                                            color="brand"
                                            className="mb-2"
                                        >
                                            Montech Offset
                                        </Typography>
                                        <Typography
                                            variant="h2"
                                            color="main"
                                            className="mb-5"
                                        >
                                            Technological Innovation for
                                            Business Success
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            color="mainLight"
                                            className="mb-5 font-medium"
                                        >
                                            Innovative web development solutions
                                            that propel business growth and
                                            elevate user experiences
                                        </Typography>
                                        <Typography
                                            variant="paragraph"
                                            color="mainLight"
                                            className="mb-6"
                                        >
                                            Since 2000, Montech Design has been
                                            at the forefront of technological
                                            innovation, providing cutting-edge
                                            solutions to businesses worldwide.
                                            Our passionate team of experts
                                            specializes in web development,
                                            software engineering, app
                                            development, Shopify, and Magento
                                            solutions. We pride ourselves on our
                                            ability to transform ideas into
                                            reality, enhancing user experiences
                                            and driving business growth.
                                        </Typography>
                                        <Button
                                            color="brand"
                                            size="lg"
                                            variant="gradient"
                                        >
                                            About us
                                        </Button>
                                    </div>
                                </div>
                                <div className="w-full h-full">
                                    <div className="w-full h-full flex items-center justify-center">
                                        <img
                                            className="h-auto w-full rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50"
                                            src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                                            alt="nature"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=""></div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Sticker;
