import React from "react";
import { Breadcrumbs, IconButton, Typography } from "@material-tailwind/react";
import { NavbarMain } from "../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import { MapPinIcon, PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import { RiWhatsappFill } from "react-icons/ri";
import Form from "../components/contactForm/Form";
import Footer from "../components/Footer";

function Contactus() {
    return (
        <div className="w-full h-full">
            <NavbarMain />
            <div className="w-full h-full">
                <div className="relative w-full h-full">
                    <img
                        className="h-[420px] w-full object-cover object-center"
                        src="https://www.material-tailwind.com/image/image-5.jpeg"
                        alt="nature"
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
                        <div className="w-full h-full bg-black opacity-50 absolute top-0 left-0"></div>
                        <div className="relative text-white mt-4">
                            <Breadcrumbs className="bg-opacity-70 bg-white opacity-100">
                                <Link to="/" className="opacity-70 text-main-500 hover:text-brand-500">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                    </svg>
                                </Link>
                                <Link to="/contact-us" className="font-bold text-main-500 hover:text-brand-500">
                                    Contact Us
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </div>
                </div>

                <div className="w-full h-full">
                    <div className="mt-[-60px] w-full h-full">
                        <div className="max-w-[1235px] h-full mx-auto px-6">
                            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 bg-[#FFFFFFCC] backdrop-blur-2xl rounded-lg shadow-lg py-2">
                                <div className="w-full h-full flex flex-col items-center justify-start p-6">
                                    <a
                                        href="https://maps.app.goo.gl/6AcHJZJXCNbMot9s7"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton size="lg" className="mb-4">
                                            <MapPinIcon className="w-5 h-5 text-white" />
                                        </IconButton>
                                    </a>
                                    <Typography
                                        variant="h5"
                                        color="main"
                                        className="mb-1"
                                    >
                                        Address
                                    </Typography>
                                    <Typography
                                        color="mainLight"
                                        className="text-center"
                                    >
                                        A-422, Yash Plaza, Opp. Dhana Mill,
                                        Varachha Road, Surat-395006, Gujarat,
                                        India.
                                    </Typography>
                                </div>

                                <div className="w-full h-full flex flex-col items-center justify-start p-6">
                                    <a href="tel:+919879328385">
                                        <IconButton size="lg" className="mb-4">
                                            <PhoneIcon className="w-5 h-5 text-white" />
                                        </IconButton>
                                    </a>
                                    <Typography
                                        variant="h5"
                                        color="main"
                                        className="mb-1"
                                    >
                                        Phone
                                    </Typography>
                                    <Typography
                                        color="mainLight"
                                        className="text-center"
                                    >
                                        +91-98793-28385
                                    </Typography>
                                </div>
                                <div className="w-full flex flex-col items-center justify-start p-6">
                                    <a href="mailto:info@montechoffset.com">
                                        <IconButton size="lg" className="mb-4">
                                            <EnvelopeIcon className="w-5 h-5 text-white" />
                                        </IconButton>
                                    </a>
                                    <Typography
                                        variant="h5"
                                        color="main"
                                        className="mb-1"
                                    >
                                        Email
                                    </Typography>
                                    <Typography
                                        color="mainLight"
                                        className="text-center"
                                    >
                                        info@montechoffice.com
                                    </Typography>
                                </div>

                                <div className="w-full flex flex-col items-center justify-start p-6">
                                    <a
                                        href="https://wa.me/919879328385"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton size="lg" className="mb-4">
                                            <RiWhatsappFill className="w-6 h-6 text-white" />
                                        </IconButton>
                                    </a>
                                    <Typography
                                        variant="h5"
                                        color="main"
                                        className="mb-1"
                                    >
                                        Whatsapp
                                    </Typography>
                                    <Typography
                                        color="mainLight"
                                        className="text-center"
                                    >
                                        +91-98793-28385
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-full py-20">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <Form />
                        </div>
                    </div>

                    <div className="w-full h-full py-20 bg-gray-100">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.609353732192!2d72.846111!3d21.207672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04efc094c0c51%3A0x43f3eb5f9c56cf0!2sMontech%20Design!5e0!3m2!1sen!2sin!4v1714477907830!5m2!1sen!2sin"
                                className="w-full h-96 rounded-lg shadow-lg"
                                allowfullscreen=""
                                title="Montech Offset"
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contactus;
