import * as React from "react";
const Goal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#0089CF"
      stroke="none"
    >
      <path d="M2506 4838 c-14 -20 -16 -92 -16 -590 l0 -567 -22 -15 c-12 -9 -37 -16 -54 -16 -61 0 -230 -51 -330 -100 -545 -264 -773 -913 -514 -1458 226 -476 763 -726 1271 -592 452 118 785 522 815 990 10 146 19 140 -186 140 -108 0 -171 -4 -175 -11 -4 -5 -11 -54 -15 -107 -17 -192 -82 -334 -215 -468 -66 -66 -97 -89 -175 -127 -127 -62 -201 -80 -330 -80 -128 0 -203 18 -325 77 -243 120 -397 370 -397 646 0 118 21 212 72 315 116 239 334 385 615 410 44 4 86 12 93 17 9 8 12 173 12 762 0 843 4 796 -70 796 -29 0 -43 -6 -54 -22z" />
      <path d="M3617 4162 l-337 -337 0 -235 0 -234 -396 -396 c-357 -358 -395 -400 -396 -430 -1 -23 6 -39 22 -52 51 -42 49 -43 465 371 214 215 403 395 418 400 17 7 120 11 250 11 l221 0 333 332 c352 351 354 354 317 406 -15 21 -20 22 -215 22 -119 0 -208 4 -220 10 -18 10 -19 23 -19 214 0 191 -1 205 -21 230 -14 18 -30 26 -52 26 -29 0 -68 -36 -370 -338z" />
      <path d="M2025 4336 c-562 -184 -1001 -619 -1184 -1173 -46 -138 -67 -232 -85 -389 -7 -63 -18 -120 -25 -127 -8 -8 -75 -13 -224 -15 -236 -4 -247 -7 -247 -68 0 -23 7 -38 26 -53 26 -21 36 -21 521 -21 l493 0 9 23 c4 12 11 72 14 132 24 449 318 865 740 1049 42 18 83 39 92 46 13 11 15 54 15 304 0 214 -3 295 -12 304 -17 17 -54 14 -133 -12z" />
      <path d="M2405 3121 c-185 -53 -336 -198 -396 -383 -34 -104 -34 -252 0 -357 80 -243 301 -404 556 -404 268 0 499 179 565 438 22 86 26 165 10 196 -9 15 -22 19 -67 19 l-57 0 -157 -155 c-172 -170 -209 -195 -299 -195 -96 0 -189 63 -231 154 -23 51 -25 143 -4 193 9 22 75 97 160 183 l145 146 0 80 c0 49 -5 85 -12 92 -18 18 -142 14 -213 -7z" />
      <path d="M3810 2611 c-5 -11 -10 -48 -10 -83 0 -154 -62 -391 -141 -538 -136 -251 -369 -464 -632 -576 -55 -23 -104 -48 -108 -56 -5 -7 -9 -135 -9 -285 0 -338 -6 -328 156 -279 559 168 981 557 1194 1102 53 135 96 305 110 434 6 58 15 116 18 130 l7 25 219 5 c214 5 218 5 237 29 24 30 24 52 -2 85 l-20 26 -504 0 c-488 0 -505 -1 -515 -19z" />
      <path d="M800 2185 c-32 -39 79 -353 195 -550 299 -509 824 -846 1403 -901 55 -5 75 -11 82 -25 6 -10 10 -105 10 -213 l0 -195 26 -20 c35 -27 59 -26 89 4 l25 24 0 494 c0 384 -3 497 -12 504 -7 6 -59 13 -115 17 -179 11 -312 44 -468 116 -293 135 -524 386 -640 695 l-23 60 -280 3 c-239 2 -281 0 -292 -13z" />
    </g>
  </svg>
);
export default Goal;
