import { Typography, IconButton } from "@material-tailwind/react";
import { FaFacebookF, FaInstagram } from "react-icons/fa6";
import React from "react";

function Footer() {
    return (
        <footer className="bg-main-500 pt-20 pb-6">
            <div className="max-w-[1235px] w-full h-full mx-auto px-6">
                <div className="w-full h-full">
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-6">
                        <div className="w-full h-full">
                            <Typography variant="h4" color="white">
                                Montech Offset
                            </Typography>
                            <Typography
                                variant="paragraph"
                                color="white"
                                className="w-full md:w-[90%]"
                            >
                                Easy to use React components for Tailwind CSS
                                and Material Design.
                            </Typography>
                            <div className="w-full h-auto flex items-center justify-start gap-4 mt-6">
                                <a href="#buttons-with-link">
                                    <IconButton
                                        variant="outlined"
                                        color="white"
                                    >
                                        <FaFacebookF className="text-lg" />
                                    </IconButton>
                                </a>
                                <a href="#buttons-with-link">
                                    <IconButton
                                        variant="outlined"
                                        color="white"
                                    >
                                        <FaInstagram className="text-lg" />
                                    </IconButton>
                                </a>
                            </div>
                        </div>
                        <div className="w-full h-full">
                            <div className="w-full grid grid-cols-2 sm:grid-cols-2 gap-6">
                                <div className="w-full h-full">
                                    <Typography
                                        variant="paragraph"
                                        color="white"
                                        className="font-semibold mb-4"
                                    >
                                        Company
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        About Us
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Products
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Contact Us
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Blog
                                    </Typography>
                                </div>
                                <div className="w-full h-full">
                                    <Typography
                                        variant="paragraph"
                                        color="white"
                                        className="font-semibold mb-4"
                                    >
                                        Products
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Sticker / Labels
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Box / Mono Carton
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Catalogue
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Hospital Stationary
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Books
                                    </Typography>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="mb-2"
                                    >
                                        Custome Printing
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-9 mb-6" />
                    <div className="w-full grid grid-cols-2">
                        <div className="w-full h-auto">
                            <Typography variant="small" color="white">
                                All rights reserved. Copyright © 2024 Montech
                                Offset.
                            </Typography>
                        </div>
                        <div className="w-auto h-auto">
                            <Typography
                                variant="small"
                                color="white"
                                className="text-right"
                            >
                                Created by
                                <a
                                    href="https://montechdesign.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className="text-white hover:text-brand-500 font-bold">
                                        &nbsp;Montech Design
                                    </span>
                                </a>
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
