import React from "react";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Typography,
} from "@material-tailwind/react";
import {
    Square3Stack3DIcon,
    UserCircleIcon,
    Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import TabImg1 from "../assets/images/tabs_img-1.png";

function Experts() {
    const data = [
        {
            label: "Lubricant",
            value: "lubricant",
            icon: Square3Stack3DIcon,
            title: "#01 Lubricant",
            image: TabImg1,
            desc: `It really matters and then like it really doesn't matter.
          What matters is the people who are sparked by it. And the people
          who are like offended by it, it doesn't matter.`,
        },
        {
            label: "Pharma",
            value: "pharma",
            icon: Square3Stack3DIcon,
            title: "#02 Pharma",
            image: TabImg1,
            desc: `Because it's about motivating the doers. Because I'm here
          to follow my dreams and inspire other people to follow their dreams, too.`,
        },

        {
            label: "Cosmetics",
            value: "cosmetics",
            icon: UserCircleIcon,
            title: "#03 Cosmetics",
            image: TabImg1,
            desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
        },

        {
            label: "FMCG",
            value: "fmcg",
            icon: UserCircleIcon,
            title: "#04 FMCG",
            image: TabImg1,
            desc: `Because it's about motivating the doers. Because I'm here
          to follow my dreams and inspire other people to follow their dreams, too.`,
        },

        {
            label: "Industrial",
            value: "industrial",
            icon: Cog6ToothIcon,
            title: "#05 Industrial",
            image: TabImg1,
            desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
        },
        {
            label: "Health Care",
            value: "healthCare",
            icon: Cog6ToothIcon,
            title: "#06 Health Care",
            image: TabImg1,
            desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams. We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
        },
    ];
    return (
        <>
            <div className="w-full h-full mb-16">
                <div className="w-full h-full">
                    <div className="w-full h-full mb-4">
                        <Typography
                            variant="h2"
                            color="main"
                            className="text-center"
                        >
                            Our Expertise
                        </Typography>
                    </div>
                    <div className="max-w-4xl mx-auto">
                        <Typography
                            variant="lead"
                            color="main"
                            className="text-center"
                        >
                            We're constantly trying to express ourselves and
                            actualize our dreams. If you have the opportunity to
                            play.
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="pt-4">
                <Tabs id="custom-animation" value="lubricant" className="w-full">
                    <TabsHeader
                        className="bg-white gap-6 w-full p-0 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6"
                        indicatorProps={{
                            className:
                                "bg-[#f2f2f2] shadow-none rounded-lg before:block before:absolute before:z-10 before:bottom-[-12px] before:left-1/2 before:transform before:-translate-x-1/2 before:h-6 before:w-6 before:bg-[#f2f2f2] before:content [''] before:transform before:rotate-45 before:opacity-100 before:transition-[0.4s] before:margin-left-[-13px]",
                        }}
                    >
                        {data.map(({ label, value, icon }) => (
                            <Tab
                                key={value}
                                value={value}
                                // className="p-5 hover:shadow-[7px_6px_30px_#0000001f] transition-all rounded-lg bg-blue-gray-50"
                                className="p-5 transition-all rounded-lg bg-gradient-to-br from-blue-200 to-blue-400 hover:bg-gradient-to-br hover:from-blue-300 hover:to-blue-500"
                            >
                                <div className="flex flex-col items-center gap-2">
                                    {React.createElement(icon, {
                                        className: "w-10 h-10",
                                    })}
                                    <Typography
                                        variant="h6"
                                        color="main"
                                        className="font-medium"
                                    >
                                        {label}
                                    </Typography>
                                </div>
                            </Tab>
                        ))}
                    </TabsHeader>
                    <TabsBody
                        animate={{
                            initial: { y: 250 },
                            mount: { y: 0 },
                            unmount: { y: 250 },
                        }}
                        className="rounded-lg shadow-none bg-[#f5f5f7] mt-10 mb-16"
                    >
                        {data.map(({ value, title, image, desc }) => (
                            <TabPanel key={value} value={value} className="p-0">
                                <div className="w-full h-full grid-cols-1 grid md:grid-cols-2 gap-8 p-6 md:p-10">
                                    <div className="w-full h-full">
                                        <div className="w-full h-full flex items-center justify-center">
                                            <img
                                                className="h-[350px] w-[350px] object-contain object-center"
                                                src={image}
                                                alt="nature"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full h-full">
                                        <div className="w-full h-full py-6">
                                            <Typography
                                                variant="h2"
                                                color="brand"
                                                className="mb-5"
                                            >
                                                {title}
                                            </Typography>
                                            <Typography
                                                variant="paragraph"
                                                color="mainLight"
                                                className="mb-5"
                                            >
                                                {desc}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    );
}

export default Experts;
