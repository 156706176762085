import React from "react";
import {
    Chip,
    Typography,
    Card,
    CardBody,
    Button,
    IconButton,
} from "@material-tailwind/react";
import { FaArrowPointer } from "react-icons/fa6";
import { MdOutlineAccessTimeFilled } from "react-icons/md";


const CoreValue = [
    {
        _id: 1,
        icon: <FaArrowPointer className="w-6 h-6 text-white" />,
        heading: "Modular Components",
        description:
            "The Arctic Ocean freezes every winter and much of the sea-ice then thaws every summer, and that process will continue whatever.",
        link: "http://localhost:3000",
    },
    {
        _id: 2,
        icon: <MdOutlineAccessTimeFilled className="w-6 h-6 text-white" />,
        heading: "Time Management",
        description:
            "The Arctic Ocean freezes every winter and much of the sea-ice then thaws every summer.",
        link: "http://localhost:3000",
    },
    {
        _id: 3,
        icon: <FaArrowPointer className="w-6 h-6 text-white" />,
        heading: "Modular Components",
        description:
            "The Arctic Ocean freezes every winter and much of the sea-ice then thaws every summer, and that process will continue whatever, and that process will continue whatever",
        link: "http://localhost:3000",
    },
    {
        _id: 4,
        icon: <MdOutlineAccessTimeFilled className="w-6 h-6 text-white" />,
        heading: "Time Management",
        description:
            "The Arctic Ocean freezes every winter and much of the sea-ice then thaws every summer, and that process will continue whatever.",
        link: "http://localhost:3000",
    },
];

function AboutCoreValue() {
    return (
        <>
            <section className="w-full h-full">
                <div className="w-full h-full mb-20">
                    <div className="w-full h-full grid-cols-1 grid md:grid-cols-2 gap-6">
                        <div className="w-full h-full">
                            <div className="w-auto h-auto mb-5">
                                <Chip
                                    color="main"
                                    variant="gradient"
                                    value="core values"
                                    className="w-[114px]"
                                />
                            </div>
                            <div className="">
                                <Typography variant="h2" color="main">
                                    Beautiful mobile Apps - The Ultimate Secret
                                </Typography>
                            </div>
                        </div>
                        <div className="w-full h-full">
                            <div className="w-full grid sm:grid-cols-3 grid-cols-2 gap-3">
                                <div className="w-full h-full flex items-center md:justify-center justify-items-start">
                                    <img
                                        src="https://www.material-tailwind.com/logos/logo-google.svg"
                                        alt="logo1"
                                        className="w-28 h-14 object-cover object-center"
                                    />
                                </div>
                                <div className="w-full h-full flex items-center md:justify-center justify-items-start">
                                    <img
                                        src="https://www.material-tailwind.com/logos/logo-amazon.svg"
                                        alt="logo1"
                                        className="w-28 h-14 object-cover object-center"
                                    />
                                </div>
                                <div className="w-full h-full flex items-center md:justify-center justify-items-start">
                                    <img
                                        src="https://www.material-tailwind.com/logos/logo-coinbase.svg"
                                        alt="logo1"
                                        className="w-28 h-14 object-cover object-center"
                                    />
                                </div>
                                <div className="w-full h-full flex items-center md:justify-center justify-items-start">
                                    <img
                                        src="https://www.material-tailwind.com/logos/logo-spotify.svg"
                                        alt="logo1"
                                        className="w-28 h-14 object-cover object-center"
                                    />
                                </div>
                                <div className="w-full h-full flex items-center md:justify-center justify-items-start">
                                    <img
                                        src="https://www.material-tailwind.com/logos/logo-netflix.svg"
                                        alt="logo1"
                                        className="w-28 h-14 object-cover object-center"
                                    />
                                </div>
                                <div className="w-full h-full flex items-center md:justify-center justify-items-start">
                                    <img
                                        src="https://www.material-tailwind.com/logos/logo-google.svg"
                                        alt="logo1"
                                        className="w-28 h-14 object-cover object-center"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full">
                    <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {CoreValue.map((value) => (
                            <Card key={value._id} color="transparent" className="mt-6 w-full shadow-none p-0">
                                <CardBody className="p-0">
                                    <IconButton
                                        variant="gradient"
                                        size="lg"
                                        color="brand"
                                        className="mb-4"
                                    >
                                        {value.icon}
                                    </IconButton>
                                    <Typography
                                        variant="h5"
                                        color="blue-gray"
                                        className="mb-2"
                                    >
                                        {value.heading}
                                    </Typography>
                                    <Typography className="mb-2">
                                        {value.description}
                                    </Typography>
                                    <a href={value.link} className="inline-block">
                                        <Button
                                            size="sm"
                                            variant="text"
                                            className="flex items-center gap-2 ml-[-1rem]"
                                        >
                                            Learn More
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={2}
                                                stroke="currentColor"
                                                className="h-4 w-4"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                                />
                                            </svg>
                                        </Button>
                                    </a>
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutCoreValue;
