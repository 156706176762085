import React from "react";
import { Typography, IconButton } from "@material-tailwind/react";
import { RiDownloadCloudFill } from "react-icons/ri";
import { UserIcon } from "@heroicons/react/24/solid";

function AboutAdvantages() {
    return (
        <section className="w-full h-full">
            <div className="w-full h-full mb-24">
                <div className="w-full flex justify-center">
                    <IconButton color="main" size="lg">
                        <UserIcon className="size-6 text-white" />
                    </IconButton>
                </div>
                <div className="w-full h-full">
                    <div className="w-full h-full mt-8 mb-4">
                        <Typography
                            variant="h2"
                            color="main"
                            className="text-center"
                        >
                            How To Handle Components
                        </Typography>
                    </div>
                    <div className="max-w-4xl mx-auto">
                        <Typography
                            variant="lead"
                            color="mainLight"
                            className="text-center"
                        >
                            We're constantly trying to express ourselves and
                            actualize our dreams. Don't stop.
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="w-full h-full">
                <div className="w-full h-full grid-cols-1 grid md:grid-cols-2 gap-8">
                    <div className="w-full h-full">
                        <div className="w-full h-full flex items-center justify-center">
                            <div className="h-[480px] md:w-[448px] w-full bg-main-500 rounded-lg shadow-lg p-14">
                                <div className="h-full flex items-center justify-center">
                                    <div className="">
                                        <Typography
                                            variant="h3"
                                            color="white"
                                            className="text-center"
                                        >
                                            Social Analytics
                                        </Typography>
                                        <Typography
                                            color="white"
                                            className="mt-2 text-center"
                                        >
                                            Insight to help you create, connect,
                                            and convert. Understand Your
                                            Audience's Interests, Influence,
                                            Interactions, and Intent. Discover
                                            emerging topics and influencer to
                                            reach new audiences.
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full">
                        <div className="w-full h-full py-10">
                            <div className="flex flex-col gap-8">
                                <div className="w-full flex gap-4">
                                    <div className="w-auto h-full">
                                        <RiDownloadCloudFill className="w-6 h-6" />
                                    </div>
                                    <div className="w-auto h-full">
                                        <Typography
                                            variant="h5"
                                            color="main"
                                            className="mb-2"
                                        >
                                            A Productivity Platform
                                        </Typography>
                                        <Typography
                                            color="mainLight"
                                            className="md:max-w-80 w-full"
                                        >
                                            Track actions taken on your website
                                            that originated from social, and
                                            understand the impact on your bottom
                                            line.
                                        </Typography>
                                    </div>
                                </div>
                                <div className="w-full flex gap-4">
                                    <div className="w-auto h-full">
                                        <RiDownloadCloudFill className="w-6 h-6" />
                                    </div>
                                    <div className="w-auto h-full">
                                        <Typography
                                            variant="h5"
                                            color="main"
                                            className="mb-2"
                                        >
                                            Performance Analyzes
                                        </Typography>
                                        <Typography
                                            color="mainLight"
                                            className="md:max-w-80 w-full"
                                        >
                                            Unify data from Facebook, Instagram,
                                            Twitter, LinkedIn, and Youtube to
                                            gain rich insights from easy-to-use
                                            reports.
                                        </Typography>
                                    </div>
                                </div>
                                <div className="w-full flex gap-4">
                                    <div className="w-auto h-full">
                                        <RiDownloadCloudFill className="w-6 h-6" />
                                    </div>
                                    <div className="w-auto h-full">
                                        <Typography
                                            variant="h5"
                                            color="main"
                                            className="mb-2"
                                        >
                                            Always In Sync
                                        </Typography>
                                        <Typography
                                            color="mainLight"
                                            className="md:max-w-80 w-full"
                                        >
                                            Gain access to the demographics,
                                            psychographics, and location of
                                            unique people who talk about your
                                            brand.
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutAdvantages;
