import React from "react";
import { NavbarMain } from "../components/Navbar/Navbar";
import {
    Typography,
    Breadcrumbs,
    Button,
    Card,
    CardBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Vision from "../assets/svgs/Vision";
import Goal from "../assets/svgs/Goal";
import Testimonials from "../components/Testimonials";
import CTASection from "../components/aboutUs/CTASection";
import Footer from "../components/Footer";
import AboutCoreValue from "../components/aboutUs/AboutCoreValue";
import AboutAdvantages from "../components/aboutUs/AboutAdvantages";

function Aboutus() {
    return (
        <div className="w-full h-full">
            <NavbarMain />
            <div className="w-full h-full">
                <div className="relative w-full h-full">
                    <img
                        className="h-96 w-full object-cover object-center"
                        src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                        alt="nature"
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
                        <div className="w-full h-full bg-black opacity-50 absolute top-0 left-0"></div>
                        <div className="relative text-white mt-14">
                            <Breadcrumbs className="bg-opacity-70 bg-white opacity-100">
                                <Link
                                    to="/"
                                    className="opacity-70 text-main-500 hover:text-brand-500"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                    </svg>
                                </Link>
                                <Link
                                    to="/about-us"
                                    className="font-bold text-main-500 hover:text-brand-500"
                                >
                                    About Us
                                </Link>
                            </Breadcrumbs>
                        </div>
                    </div>
                </div>

                <div className="w-full h-full">
                    <div className="w-full h-full py-20">
                        <div className="max-w-[1235px] h-full mx-auto px-6">
                            <div className="w-full h-full grid-cols-1 grid md:grid-cols-2 gap-6">
                                <div className="w-full h-full">
                                    <div className="w-full h-full py-6">
                                        <Typography
                                            variant="h6"
                                            color="brand"
                                            className="mb-2"
                                        >
                                            Montech Offset
                                        </Typography>
                                        <Typography
                                            variant="h2"
                                            color="main"
                                            className="mb-5"
                                        >
                                            Technological Innovation for
                                            Business Success
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            color="mainLight"
                                            className="mb-5 font-medium"
                                        >
                                            Innovative web development solutions
                                            that propel business growth and
                                            elevate user experiences
                                        </Typography>
                                        <Typography
                                            variant="paragraph"
                                            color="mainLight"
                                            className="mb-6"
                                        >
                                            Since 2000, Montech Design has been
                                            at the forefront of technological
                                            innovation, providing cutting-edge
                                            solutions to businesses worldwide.
                                            Our passionate team of experts
                                            specializes in web development,
                                            software engineering, app
                                            development, Shopify, and Magento
                                            solutions. We pride ourselves on our
                                            ability to transform ideas into
                                            reality, enhancing user experiences
                                            and driving business growth.
                                        </Typography>
                                        <Button
                                            color="brand"
                                            size="lg"
                                            variant="gradient"
                                        >
                                            About us
                                        </Button>
                                    </div>
                                </div>
                                <div className="w-full h-full">
                                    <div className="w-full h-full flex items-center justify-center">
                                        <img
                                            className="h-auto w-full rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50"
                                            src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                                            alt="nature"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-full py-20 bg-gray-100">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                <Card className="w-full">
                                    <CardBody>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="mb-4 h-12 w-12 text-brand-500"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
                                                clipRule="evenodd"
                                            />
                                            <path d="M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z" />
                                        </svg>
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Mission
                                        </Typography>
                                        <Typography>
                                            At Montech Offest, our mission is to
                                            innovate, create, and deliver
                                            exceptional digital solutions,
                                            empowering businesses for a
                                            brighter, tech-driven future.
                                        </Typography>
                                    </CardBody>
                                </Card>

                                <Card className="w-full">
                                    <CardBody>
                                        <Vision className="mb-4 h-12 w-12" />
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Vision
                                        </Typography>
                                        <Typography>
                                            Our vision is to be the catalyst for
                                            transformative change, redefining
                                            industry standards and inspiring a
                                            world where technology enhances
                                            every aspect of business and life.
                                        </Typography>
                                    </CardBody>
                                </Card>

                                <Card className="w-full">
                                    <CardBody>
                                        <Goal className="mb-4 h-12 w-12" />
                                        <Typography
                                            variant="h5"
                                            color="blue-gray"
                                            className="mb-2"
                                        >
                                            Goal
                                        </Typography>
                                        <Typography>
                                            Our goal is to consistently exceed
                                            expectations, fostering long-term
                                            partnerships. We strive to make a
                                            meaningful impact by embracing
                                            challenges, fostering innovation,
                                            and driving digital excellence.
                                        </Typography>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-full py-20">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <AboutAdvantages />
                        </div>
                    </div>

                    <div className="w-full h-full py-20 bg-gray-100">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <AboutCoreValue />
                        </div>
                    </div>

                    <div className="w-full h-full py-20">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <CTASection />
                        </div>
                    </div>

                    <div className="w-full h-full py-20 bg-gray-100">
                        <div className="max-w-[1235px] mx-auto px-6">
                            <Testimonials />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Aboutus;
