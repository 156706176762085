import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Typography,
    IconButton,
    Card,
    CardHeader,
    CardBody,
    Avatar,
} from "@material-tailwind/react";
import { UserIcon } from "@heroicons/react/24/solid";
import "../App.css";

const TestimonialData = [
    {
        _id: 1,
        avatar: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
        name: "Jone Doe",
        company: "Google",
        description:
            "I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!",
    },
    {
        _id: 2,
        avatar: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
        name: "Riden Parker",
        company: "Google",
        description:
            "I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!",
    },
    {
        _id: 3,
        avatar: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
        name: "Adam Smith",
        company: "Google",
        description:
            "I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!",
    },
    {
        _id: 4,
        avatar: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
        name: "Tim Devid",
        company: "Google",
        description:
            "I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!",
    },
    {
        _id: 5,
        avatar: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
        name: "Devid M",
        company: "Google",
        description:
            "I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!",
    },
];

function TestiWhite(props) {
    const { index, avatar, name, company, description } = props;
    return (
        <div>
            <Card
                key={index}
                color="transparent"
                shadow={false}
                className="w-full p-6"
            >
                <CardHeader
                    color="transparent"
                    floated={false}
                    shadow={false}
                    className="mx-0 flex flex-col items-center justify-start gap-6 pt-0 pb-5"
                >
                    <Avatar
                        size="lg"
                        variant="rounded"
                        src={avatar}
                        alt="tania andrew"
                    />
                    <div className="flex w-full flex-col items-center gap-0.5">
                        <div className="flex items-center justify-between">
                            <Typography variant="h5" color="main">
                                {name}
                            </Typography>
                        </div>
                        <Typography color="main">{company}</Typography>
                    </div>
                </CardHeader>
                <CardBody className="mb-6 p-0">
                    <Typography
                        variant="paragraph"
                        color="main"
                        className="text-center"
                    >
                        &quot;{description}&quot;
                    </Typography>
                </CardBody>
            </Card>
        </div>
    );
}

function Testimonials() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            <div className="w-full h-full mb-16">
                <div className="w-full flex justify-center">
                    <IconButton color="main" variant="gradient">
                        <UserIcon className="size-6 text-white" />
                    </IconButton>
                </div>
                <div className="w-full h-full">
                    <div className="w-full h-full mt-8 mb-4">
                        <Typography
                            variant="h2"
                            color="main"
                            className="text-center"
                        >
                            What Clients Say
                        </Typography>
                    </div>
                    <div className="max-w-4xl mx-auto">
                        <Typography
                            variant="lead"
                            color="main"
                            className="text-center"
                        >
                            We're constantly trying to express ourselves and
                            actualize our dreams. If you have the opportunity to
                            play.
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="slider-container">
                <Slider {...settings}>
                    {TestimonialData.map((value) => (
                        <TestiWhite
                            index={value._id}
                            avatar={value.avatar}
                            name={value.name}
                            company={value.company}
                            description={value.description}
                        />
                    ))}
                </Slider>
            </div>
        </>
    );
}

export default Testimonials;
