import React from "react";
import {
    Input,
    Typography,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
    Textarea,
} from "@material-tailwind/react";
import { useCountries } from "use-react-countries";
import { useFormik } from "formik";
import { formSchema } from "../schemas";
import { MdError } from "react-icons/md";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
    name: "",
    email: "",
    number: "",
    message: "",
    country: "",
};

function Form() {
    const { countries } = useCountries();
    const sortedCountries = countries
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
    const indiaIndex = sortedCountries.findIndex(
        (country) => country.name === "India"
    );
    const [country, setCountry] = React.useState(
        indiaIndex !== -1 ? indiaIndex : 0
    );
    const { name, flags, countryCallingCode } = sortedCountries[country];

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: initialValues,
            validationSchema: formSchema,
            onSubmit: (values, action) => {
                const fullPhoneNumber = `${sortedCountries[country].countryCallingCode}${values.number}`;
                const countryName = `${sortedCountries[country].name}`;
                const formData = {
                    ...values,
                    number: fullPhoneNumber,
                    country: countryName,
                };
                const sendingToastId = toast.info("Sending...", {
                    autoClose: false,
                });

                emailjs
                    .send(
                        "service_9hi8zze",
                        "template_li51urs",
                        formData,
                        "NJPHlaeRF2s2k3rtO"
                    )
                    .then(
                        (response) => {
                            console.log("Sent message successfully:", response);
                            action.resetForm();
                            toast.update(sendingToastId, {
                                render: "Sent message successfully",
                                type: "success",
                                autoClose: 5000, // Adjust autoClose delay if needed
                            });
                        },
                        (error) => {
                            console.log("FAILED...", error.text);
                            toast.error("Something went wrong");
                        }
                    );
                console.log("🚀 ~ Form ~ values:", formData);
            },
        });

    return (
        <>
            <ToastContainer position="top-right" />
            <section className="w-full h-full bg-white">
                <div className="w-full h-full">
                    <div className="w-full h-full grid-cols-1 grid md:grid-cols-2 gap-6">
                        <div className="w-full h-full">
                            <div className="w-full h-full py-6">
                                <Typography
                                    variant="h2"
                                    color="main"
                                    className="mb-4"
                                >
                                    Get in Touch
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color="mainLight"
                                    className="font-normal mb-16 xl:pr-14"
                                >
                                    You need more information? Check what other
                                    persons are saying about our product. They
                                    are very happy with their purchase.
                                </Typography>
                                <div className="w-full h-auto xl:pr-14">
                                    <form>
                                        <div className="mb-4">
                                            <Input
                                                type="text"
                                                name="name"
                                                label="Name"
                                                size="lg"
                                                placeholder=""
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    errors.name && touched.name
                                                }
                                            />
                                            {errors.name && touched.name ? (
                                                <Typography
                                                    variant="small"
                                                    color="red"
                                                    className="mt-2 flex items-center gap-1 font-normal"
                                                >
                                                    <MdError className="text-sm" />
                                                    {errors.name}
                                                </Typography>
                                            ) : null}
                                        </div>
                                        <div className="mb-4">
                                            <Input
                                                variant="outlined"
                                                type="email"
                                                name="email"
                                                label="Email"
                                                size="lg"
                                                placeholder=""
                                                className="mb-4"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    errors.email &&
                                                    touched.email
                                                }
                                            />
                                            {errors.email && touched.email ? (
                                                <Typography
                                                    variant="small"
                                                    color="red"
                                                    className="mt-2 flex items-center gap-1 font-normal"
                                                >
                                                    <MdError className="text-sm" />
                                                    {errors.email}
                                                </Typography>
                                            ) : null}
                                        </div>
                                        <div className="mb-4">
                                            <div className="relative flex w-full">
                                                <Menu placement="bottom-start">
                                                    <MenuHandler>
                                                        <Button
                                                            ripple={false}
                                                            variant="text"
                                                            color="blue-gray"
                                                            className="flex h-11 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
                                                        >
                                                            <img
                                                                src={flags.svg}
                                                                alt={name}
                                                                className="h-4 w-4 rounded-full object-cover"
                                                            />
                                                            {countryCallingCode}
                                                        </Button>
                                                    </MenuHandler>
                                                    <MenuList className="max-h-[20rem] max-w-[18rem]">
                                                        {sortedCountries.map(
                                                            (
                                                                {
                                                                    name,
                                                                    flags,
                                                                    countryCallingCode,
                                                                },
                                                                index
                                                            ) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            name
                                                                        }
                                                                        value={
                                                                            name
                                                                        }
                                                                        className="flex items-center gap-2"
                                                                        onClick={() =>
                                                                            setCountry(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                flags.svg
                                                                            }
                                                                            alt={
                                                                                name
                                                                            }
                                                                            className="h-5 w-5 rounded-full object-cover"
                                                                        />
                                                                        {name}{" "}
                                                                        <span className="ml-auto">
                                                                            {
                                                                                countryCallingCode
                                                                            }
                                                                        </span>
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </MenuList>
                                                </Menu>
                                                <Input
                                                    type="tel"
                                                    placeholder=""
                                                    size="lg"
                                                    label="Mobile"
                                                    name="number"
                                                    variant="outlined"
                                                    className="rounded-l-none"
                                                    labelProps={{
                                                        className:
                                                            "before:content-none pl-2",
                                                    }}
                                                    containerProps={{
                                                        className: "min-w-0",
                                                    }}
                                                    value={values.number}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.number &&
                                                        touched.number
                                                    }
                                                />
                                            </div>
                                            {errors.number && touched.number ? (
                                                <Typography
                                                    variant="small"
                                                    color="red"
                                                    className="mt-2 flex items-center gap-1 font-normal"
                                                >
                                                    <MdError className="text-sm" />
                                                    {errors.number}
                                                </Typography>
                                            ) : null}
                                        </div>
                                        <div className="mb-6">
                                            <Textarea
                                                name="message"
                                                type="text"
                                                variant="outlined"
                                                size="lg"
                                                label="Message"
                                                placeholder=""
                                                className="min-h-[100px]"
                                                resize={true}
                                                value={values.message}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    errors.message &&
                                                    touched.message
                                                }
                                            />
                                            {errors.message &&
                                            touched.message ? (
                                                <Typography
                                                    variant="small"
                                                    color="red"
                                                    className="mt-2 flex items-center gap-1 font-normal"
                                                >
                                                    <MdError className="text-sm" />
                                                    {errors.message}
                                                </Typography>
                                            ) : null}
                                        </div>
                                        <div>
                                            <Button
                                                variant="solid"
                                                color="main"
                                                fullWidth
                                                size="lg"
                                                onClick={handleSubmit}
                                            >
                                                Send Message
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="w-full h-full">
                            <div className="w-full h-full flex items-center justify-center">
                                <img
                                    className="h-auto w-full rounded-lg object-cover object-center shadow-xl shadow-blue-gray-900/50"
                                    src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                                    alt="Nature"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Form;

// const indiaIndex = countries.findIndex(
//     (country) => country.name === "India"
// );

// const [country, setCountry] = React.useState(
//     indiaIndex !== -1 ? indiaIndex : 0
// );
// const { name, flags, countryCallingCode } = countries[country];
