import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";

const theme = {
    typography: {
        defaultProps: {
            variant: "paragraph",
            color: "inherit",
            textGradient: false,
            className: "",
        },
        valid: {
            variants: [
                "h1",
                "h2",
                "h3",
                "h4",
                "h5",
                "h6",
                "lead",
                "paragraph",
                "small",
            ],
            colors: ["main", "brand", "mainLight"],
        },
        styles: {
            variants: {
                h1: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    letterSpacing: "tracking-normal",
                    fontFamily: "font-sans",
                    fontSize: "text-5xl",
                    fontWeight: "font-semibold",
                    lineHeight: "leading-tight",
                },
                h2: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    letterSpacing: "tracking-normal",
                    fontFamily: "font-sans",
                    fontSize: "text-4xl",
                    fontWeight: "font-semibold",
                    lineHeight: "leading-[1.3]",
                },
                h3: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    letterSpacing: "tracking-normal",
                    fontFamily: "font-sans",
                    fontSize: "text-3xl",
                    fontWeight: "font-semibold",
                    lineHeight: "leading-snug",
                },
                h4: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    letterSpacing: "tracking-normal",
                    fontFamily: "font-sans",
                    fontSize: "text-2xl",
                    fontWeight: "font-semibold",
                    lineHeight: "leading-snug",
                },
                h5: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    letterSpacing: "tracking-normal",
                    fontFamily: "font-sans",
                    fontSize: "text-xl",
                    fontWeight: "font-semibold",
                    lineHeight: "leading-snug",
                },
                h6: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    letterSpacing: "tracking-normal",
                    fontFamily: "font-sans",
                    fontSize: "text-lg",
                    fontWeight: "font-semibold",
                    lineHeight: "leading-relaxed",
                },
                lead: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    fontFamily: "font-sans",
                    fontSize: "text-xl",
                    fontWeight: "font-normal",
                    lineHeight: "leading-relaxed",
                },
                paragraph: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    fontFamily: "font-sans",
                    fontSize: "text-base",
                    fontWeight: "font-normal",
                    lineHeight: "leading-relaxed",
                },
                small: {
                    display: "block",
                    fontSmoothing: "antialiased",
                    fontFamily: "font-sans",
                    fontSize: "text-sm",
                    fontWeight: "font-normal",
                    lineHeight: "leading-normal",
                },
            },
            colors: {
                main: {
                    color: "text-main-500",
                },
                brand: {
                    color: "text-brand-500",
                },
                mainLight: {
                    color: "text-main-300",
                },
            },
        },
    },
    button: {
        defaultProps: {
            variant: "filled",
            size: "md",
            color: "main",
            fullWidth: false,
            ripple: true,
            className: "",
        },
        valid: {
            colors: ["brand", "main"],
        },
        styles: {
            // sizes: {
            //     sm: {
            //         fontSize: "text-xs",
            //         py: "py-2",
            //         px: "px-4",
            //         borderRadius: "rounded-lg",
            //     },
            //     md: {
            //         fontSize: "text-xs",
            //         py: "py-3",
            //         px: "px-6",
            //         borderRadius: "rounded-lg",
            //     },
            //     lg: {
            //         fontSize: "text-sm",
            //         py: "py-3.5",
            //         px: "px-7",
            //         borderRadius: "rounded-lg",
            //     },
            // },
            variants: {
                filled: {
                    brand: {
                        backgroud: "bg-brand-500",
                        color: "text-white",
                        shadow: "shadow-md shadow-brand-500/20",
                        hover: "hover:shadow-lg hover:shadow-brand-500/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                    main: {
                        backgroud: "bg-main-500",
                        color: "text-white",
                        shadow: "shadow-md shadow-main-500/20",
                        hover: "hover:shadow-lg hover:shadow-main-500/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                },
                gradient: {
                    brand: {
                        backgroud:
                            "bg-gradient-to-tr from-brand-600 to-brand-400",
                        color: "text-white",
                        shadow: "shadow-md shadow-brand-500/20",
                        hover: "hover:shadow-lg hover:shadow-brand-500/40",
                        active: "active:opacity-[0.85]",
                    },
                    main: {
                        backgroud:
                            "bg-gradient-to-tr from-main-600 to-main-400",
                        color: "text-white",
                        shadow: "shadow-md shadow-main-500/20",
                        hover: "hover:shadow-lg hover:shadow-main-500/40",
                        active: "active:opacity-[0.85]",
                    },
                },
                outlined: {
                    brand: {
                        border: "border border-brand-500",
                        color: "text-brand-500",
                        hover: "hover:opacity-75",
                        focus: "focus:ring focus:ring-brand-200",
                        active: "active:opacity-[0.85]",
                    },
                    main: {
                        border: "border border-main-500",
                        color: "text-main-500",
                        hover: "hover:opacity-75",
                        focus: "focus:ring focus:ring-main-200",
                        active: "active:opacity-[0.85]",
                    },
                },
                text: {
                    brand: {
                        color: "text-brand-500",
                        hover: "hover:bg-brand-500/10",
                        active: "active:bg-brand-500/30",
                    },
                    main: {
                        color: "text-main-500",
                        hover: "hover:bg-main-500/10",
                        active: "active:bg-main-500/30",
                    },
                },
            },
        },
    },
    iconButton: {
        defaultProps: {
            variant: "filled",
            size: "md",
            color: "main",
            fullWidth: false,
            ripple: true,
            className: "",
        },
        valid: {
            colors: ["brand", "main"],
        },
        styles: {
            variants: {
                filled: {
                    brand: {
                        backgroud: "bg-brand-500",
                        color: "text-white",
                        shadow: "shadow-md shadow-brand-500/20",
                        hover: "hover:shadow-lg hover:shadow-brand-500/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                    main: {
                        backgroud: "bg-main-500",
                        color: "text-white",
                        shadow: "shadow-md shadow-main-500/20",
                        hover: "hover:shadow-lg hover:shadow-main-500/40",
                        focus: "focus:opacity-[0.85] focus:shadow-none",
                        active: "active:opacity-[0.85] active:shadow-none",
                    },
                },
                gradient: {
                    brand: {
                        backgroud:
                            "bg-gradient-to-tr from-brand-600 to-brand-400",
                        color: "text-white",
                        shadow: "shadow-md shadow-brand-500/20",
                        hover: "hover:shadow-lg hover:shadow-brand-500/40",
                        active: "active:opacity-[0.85]",
                    },
                    main: {
                        backgroud:
                            "bg-gradient-to-tr from-main-600 to-main-400",
                        color: "text-white",
                        shadow: "shadow-md shadow-main-500/20",
                        hover: "hover:shadow-lg hover:shadow-main-500/40",
                        active: "active:opacity-[0.85]",
                    },
                },
                outlined: {
                    brand: {
                        border: "border border-brand-500",
                        color: "text-brand-500",
                        hover: "hover:opacity-75",
                        focus: "focus:ring focus:ring-brand-200",
                        active: "active:opacity-[0.85]",
                    },
                    main: {
                        border: "border border-main-500",
                        color: "text-main-500",
                        hover: "hover:opacity-75",
                        focus: "focus:ring focus:ring-main-200",
                        active: "active:opacity-[0.85]",
                    },
                },
                text: {
                    brand: {
                        color: "text-brand-500",
                        hover: "hover:bg-brand-500/10",
                        active: "active:bg-brand-500/30",
                    },
                    main: {
                        color: "text-main-500",
                        hover: "hover:bg-main-500/10",
                        active: "active:bg-main-500/30",
                    },
                },
            },
        },
    },
    // input: {
    //     defaultProps: {
    //         variant: "outlined",
    //         size: "md",
    //         color: "main",
    //         label: "",
    //         error: false,
    //         success: false,
    //         icon: undefined,
    //         labelProps: undefined,
    //         containerProps: undefined,
    //         shrink: false,
    //         className: "",
    //     },
    //     valid: {
    //         colors: ["main"],
    //     },
    //     styles: {
    //         base: {
    //             container: {
    //                 position: "relative",
    //                 width: "w-full",
    //                 minWidth: "min-w-[200px]",
    //             },
    //         },
    //         variants: {
    //             outlined: {
    //                 colors: {
    //                     input: {
    //                         main: {
    //                             borderColor: "border-main-500",
    //                             borderColorFocused: "focus:border-main-500",
    //                         },
    //                     },
    //                     label: {
    //                         main: {
    //                             color: "text-main-400 peer-focus:text-main-500",
    //                             before: "before:border-main-200 peer-focus:before:!border-main-500",
    //                             after: "after:border-main-200 peer-focus:after:!border-main-500",
    //                         },
    //                     },
    //                 },
    //             },
    //             standard: {
    //                 colors: {
    //                     input: {
    //                         main: {
    //                             borderColor: "border-main-200",
    //                             borderColorFocused: "focus:border-main-500",
    //                         },
    //                     },
    //                     label: {
    //                         main: {
    //                             color: "text-main-500 peer-focus:text-main-500",
    //                             after: "after:border-main-500 peer-focus:after:border-main-500",
    //                         },
    //                     },
    //                 },
    //             },
    //             colors: {
    //                 input: {
    //                     main: {
    //                         borderColor: "border-main-200",
    //                         borderColorFocused: "focus:border-main-500",
    //                     },
    //                 },
    //                 label: {
    //                     main: {
    //                         color: "text-main-500 peer-focus:text-main-500",
    //                         after: "after:border-main-500 peer-focus:after:border-main-500",
    //                     },
    //                 },
    //             },
    //         },
    //     },
    // },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider value={theme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
