import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Typography,
    IconButton,
} from "@material-tailwind/react";
import {
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import "../App.css";

function TestiWhite(props) {
    const { index, ...otherProps } = props;
    return (
        <div {...otherProps}>
            <figure className="relative h-full w-full px-1">
                <img
                    className="min-h-72 h-full w-full rounded-xl object-cover object-center"
                    src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                    alt="nature"
                />
                <figcaption className="absolute bottom-4 left-2/4 flex w-[calc(100%-2rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/75 py-2 px-4 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                    <div>
                        <Typography variant="h6" className="text-md" color="blue-gray">
                            Sara Lamalo
                        </Typography>
                        <Typography color="gray" variant="small" className="font-normal">
                            Box
                        </Typography>
                    </div>
                </figcaption>
            </figure>
        </div>
    );
}

function ProductSlider() {
    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    var settings = {
        className: "center",
        centerMode: true,
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        centerPadding: "60px",
        responsive: [
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "30px",
                },
            },
        ],
    };
    return (
        <>
            <div className="w-full h-full mb-16">
                <div className="w-full h-full">
                    <div className="w-full h-full mt-8 mb-4">
                        <Typography
                            variant="h2"
                            color="main"
                            className="text-center"
                        >
                            Our Latest Projects
                        </Typography>
                    </div>
                    <div className="max-w-4xl mx-auto">
                        <Typography
                            variant="lead"
                            color="main"
                            className="text-center"
                        >
                            We're constantly trying to express ourselves and
                            actualize our dreams. If you have the opportunity to
                            play.
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="slider-container">
                <Slider
                    ref={(slider) => {
                        sliderRef = slider;
                    }}
                    {...settings}
                >
                    <TestiWhite index={1} />
                    <TestiWhite index={2} />
                    <TestiWhite index={3} />
                    <TestiWhite index={4} />
                    <TestiWhite index={5} />
                    <TestiWhite index={6} />
                </Slider>
                <div className="text-center mt-6">
                    <IconButton
                        color="main"
                        variant="gradient"
                        className="w-auto h-auto p-6 mx-3"
                        onClick={previous}
                    >
                        <ArrowLongLeftIcon className="size-6 text-white" />
                    </IconButton>
                    <IconButton
                        color="main"
                        variant="gradient"
                        className="w-auto h-auto p-6 mx-3"
                        onClick={next}
                    >
                        <ArrowLongRightIcon className="size-6 text-white" />
                    </IconButton>
                </div>
            </div>
        </>
    );
}

export default ProductSlider;
