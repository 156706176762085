import * as React from "react";
const Vision = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#0089CF"
      stroke="none"
    >
      <path d="M2014 5100 c-37 -15 -46 -57 -42 -215 3 -140 4 -148 27 -166 32 -26 82 -25 109 4 21 22 22 33 22 184 l0 162 -26 20 c-28 22 -55 25 -90 11z" />
      <path d="M702 4554 c-34 -24 -44 -59 -28 -97 7 -18 59 -76 114 -129 84 -81 106 -98 132 -98 42 0 80 39 80 83 0 29 -14 47 -113 145 -120 118 -140 128 -185 96z" />
      <path d="M3228 4473 c-108 -105 -133 -142 -124 -179 9 -35 46 -64 80 -64 25 0 49 19 143 113 98 98 113 117 113 145 0 43 -38 82 -80 82 -26 0 -48 -17 -132 -97z" />
      <path d="M1875 4356 c-181 -31 -324 -85 -467 -178 -436 -285 -646 -847 -501 -1347 55 -192 145 -339 326 -528 67 -70 138 -153 158 -183 87 -132 142 -274 163 -424 l12 -81 486 -3 486 -2 11 79 c27 184 104 368 205 492 l53 66 -74 99 c-41 54 -78 102 -82 107 -4 4 -180 41 -392 82 -211 42 -413 82 -449 90 -72 16 -100 40 -100 85 0 15 8 37 18 48 9 11 121 74 247 140 127 66 345 179 485 252 140 73 359 187 485 252 127 66 234 124 239 128 10 10 -21 94 -68 187 -128 252 -369 469 -636 573 -175 67 -431 95 -605 66z" />
      <path d="M4620 4119 c-195 -105 -609 -327 -920 -494 -579 -310 -1111 -592 -1465 -775 -171 -89 -196 -105 -175 -111 14 -4 158 -33 320 -65 l296 -58 389 261 c215 143 565 378 779 522 396 265 418 277 465 251 27 -14 44 -61 32 -91 -5 -13 -183 -269 -394 -569 -211 -300 -383 -546 -382 -548 3 -4 800 -160 803 -157 3 2 609 1964 618 2003 4 12 2 22 -2 21 -5 0 -169 -86 -364 -190z" />
      <path d="M3855 3213 c-44 -31 -297 -200 -563 -378 l-482 -322 215 -286 c119 -158 219 -287 223 -287 5 0 29 108 54 240 l47 239 296 422 c163 231 295 422 293 424 -2 2 -39 -22 -83 -52z" />
      <path d="M159 3250 c-9 -5 -22 -23 -29 -40 -15 -35 0 -84 31 -100 10 -6 87 -10 172 -10 148 0 154 1 175 23 32 34 30 89 -4 116 -24 19 -40 21 -178 21 -83 0 -158 -5 -167 -10z" />
      <path d="M3486 2278 c-12 -36 -56 -291 -52 -296 3 -3 54 41 113 98 60 57 123 118 141 135 l34 32 -89 17 c-48 9 -100 19 -116 22 -17 4 -29 1 -31 -8z" />
      <path d="M788 2033 c-55 -54 -106 -110 -114 -125 -18 -35 -9 -72 23 -98 48 -38 68 -28 190 92 99 98 113 116 113 145 0 44 -38 83 -80 83 -26 0 -48 -17 -132 -97z" />
      <path d="M3241 1761 c-22 -22 -49 -130 -57 -234 -16 -184 40 -327 191 -486 58 -61 95 -109 95 -121 0 -28 -10 -46 -69 -124 -84 -111 -88 -194 -14 -303 35 -53 84 -97 161 -148 23 -15 41 -29 39 -31 -1 -2 -90 -8 -197 -14 -224 -12 -409 -37 -589 -80 -248 -58 -397 -59 -526 -2 -50 22 -78 26 -172 30 -78 2 -113 0 -113 -8 0 -6 20 -33 45 -59 58 -62 160 -113 290 -146 148 -38 227 -33 514 30 205 44 337 62 561 74 306 17 343 24 383 68 21 24 32 81 23 117 -9 36 -62 86 -165 155 -159 107 -174 146 -91 247 57 69 80 126 80 193 -1 70 -31 124 -130 226 -98 102 -132 161 -151 261 -12 65 -6 159 16 246 16 58 13 80 -13 111 -21 24 -86 23 -111 -2z" />
      <path d="M1458 1434 c-56 -30 -74 -112 -38 -171 36 -60 14 -58 632 -58 557 0 567 0 595 21 15 11 37 38 47 59 17 35 18 42 5 79 -9 25 -27 49 -48 63 l-34 23 -566 0 c-481 -1 -570 -3 -593 -16z" />
      <path d="M1458 1024 c-53 -28 -73 -105 -43 -164 32 -62 18 -61 649 -58 l574 3 26 24 c57 54 55 135 -5 186 l-31 25 -571 0 c-486 -1 -576 -3 -599 -16z" />
      <path d="M1750 563 c0 -60 5 -86 20 -110 35 -58 56 -63 288 -63 201 0 210 1 243 23 42 28 61 81 57 162 l-3 60 -302 3 -303 2 0 -77z" />
    </g>
  </svg>
);
export default Vision;
